@import url(../../App.css);

.social-media-carousel-slide-light-theme {
	border: 0.5rem solid var(--main-font-color);
	border-radius: 2rem;
	background: var(--brand-color-purple);
	display: flex;
	flex-flow: row nowrap;
	height: 25rem;
	justify-content: space-between;
	align-items: center;
	cursor: pointer;
}

.social-media-carousel-slide-dark-theme {
	border: 0.5rem solid #ffffff;
	border-radius: 2rem;
	background: var(--brand-color-purple);
	display: flex;
	flex-flow: row nowrap;
	height: 25rem;
	justify-content: space-between;
	align-items: center;
	cursor: pointer;
}

.social-media-carousel-slide-logo {
	flex: 1;
	height: 5rem;
	width: 5rem;
}

.social-media-carousel-slide-content {
	text-align: left;
	color: var(--brand-color-white);
	flex: 1;
	padding: 2rem;
}

.social-media-carousel-slide-content h1 {
	font-size: 2rem;
}

.social-media-carousel-slide-content p {
	margin: 2rem auto;
	font-size: 1.5rem;
}

.social-media-carousel-slide-graphic {
	flex: 1;
	/* background: url(../../img/carousel-graphic-bg.svg) no-repeat; */
	background-size: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	background: #2f2f3a;
	border-radius: 1rem;
}

.carousel-button-light-theme {
	text-transform: uppercase;
	text-decoration: none;
	font-weight: 500;
	text-align: center;
	font-size: 0.75rem;
	color: var(--brand-color-purple);
	background: var(--brand-color-grey);
	padding: 1rem 1rem 2rem 1rem;
	border-radius: 5rem;
	box-shadow: 0rem 0.1rem 0.313rem 0rem rgb(158, 158, 158);
	cursor: pointer;
	width: 15rem;
	height: 2.5rem;
	transition: 0.5s background linear;
	display: inline-block;
}

.carousel-button-light-theme:hover {
	background: #ffffff;
}

.carousel-button-dark-theme {
	text-transform: uppercase;
	text-decoration: none;
	font-weight: 500;
	text-align: center;
	font-size: 0.75rem;
	color: var(--brand-color-white);
	background: var(--main-font-color);
	padding: 1rem 1rem 2rem 1rem;
	border-radius: 5rem;
	box-shadow: 0rem 0.1rem 0.313rem 0rem rgb(51, 51, 51);
	cursor: pointer;
	width: 15rem;
	height: 2.5rem;
	transition: 0.5s background linear;
	display: inline-block;
}

.carousel-button-dark-theme:hover {
	background: #1f1f1f;
}

.carousel-root {
	display: flex;
	flex-flow: column nowrap;
}

.carousel .thumbs-wrapper {
	display: flex;
	justify-content: center;
}

.carousel .thumb {
	border-radius: 1rem;
	background-color: rgb(255, 255, 255);
}

.carousel .thumb:hover {
	border: 0.3rem solid var(--brand-color-purple);
}

.carousel .thumb.selected {
	border: 0.3rem solid var(--brand-color-purple);
}

.selected {
	/* border: 0.2rem solid var(--brand-color-purple) !important; */
	border-radius: 2.2rem;
}

.thumbs-wrapper {
	border-radius: 1rem;
}

.thumbs {
	display: flex;
	flex-flow: row wrap;
	cursor: pointer;
}

/* 
  ##Device = Desktops
  ##Screen = 1281px to higher resolution desktops
*/

@media (min-width: 1281px) {
	/* CSS */
}

/* 
	##Device = Laptops, Desktops
	##Screen = B/w 1025px to 1280px
  */

@media (min-width: 1025px) and (max-width: 1280px) {
	/* CSS */
	.social-media-carousel-slide-light-theme,
	.social-media-carousel-slide-dark-theme {
		flex-flow: column nowrap;
		height: 40rem;
		padding: 2rem;
	}

	.social-media-carousel-slide-graphic {
		width: 100%;
		padding: 2rem;
	}
}

/* 
	##Device = Tablets, Ipads (portrait)
	##Screen = B/w 768px to 1024px
  */

@media (min-width: 768px) and (max-width: 1024px) {
	/* CSS */
	.social-media-carousel-slide-light-theme,
	.social-media-carousel-slide-dark-theme {
		flex-flow: column nowrap;
		height: 40rem;
		padding: 2rem;
	}

	.social-media-carousel-slide-graphic {
		width: 100%;
		padding: 2rem;
	}
	.control-arrow,
	.thumbs {
		display: none;
	}
}

/* 
	##Device = Tablets, Ipads (landscape)
	##Screen = B/w 768px to 1024px
  */

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
	/* CSS */
	.social-media-carousel-slide-light-theme,
	.social-media-carousel-slide-dark-theme {
		flex-flow: column nowrap;
		height: 40rem;
		padding: 2rem;
	}
	.social-media-carousel-slide-graphic {
		width: 100%;
		padding: 2rem;
	}
	.control-arrow,
	.thumbs {
		display: none;
	}
}

/* 
	##Device = Low Resolution Tablets, Mobiles (Landscape)
	##Screen = B/w 481px to 767px
  */

@media (min-width: 481px) and (max-width: 767px) {
	/* CSS */
	.social-media-carousel-slide-light-theme,
	.social-media-carousel-slide-dark-theme {
		flex-flow: column nowrap;
		height: 60rem;
		padding: 2rem;
	}

	.social-media-carousel-slide-graphic {
		width: 100%;
		padding: 2rem;
	}
	.social-media-carousel-slide-content {
		text-align: center;
	}
	.social-media-carousel-slide-content p {
		font-size: 1rem;
		padding: 1rem;
	}
	.carousel-button-light-theme,
	.carousel-button-dark-theme {
		width: 10rem;
	}
	.carousel .slide img {
		display: none;
	}
	.control-arrow,
	.thumbs {
		display: none;
	}
}

/* 
	##Device = Most of the Smartphones Mobiles (Portrait)
	##Screen = B/w 320px to 479px
  */

@media (min-width: 320px) and (max-width: 480px) {
	/* CSS */
	.social-media-carousel-slide-light-theme,
	.social-media-carousel-slide-dark-theme {
		flex-flow: column nowrap;
		height: 60rem;
		padding: 2rem;
	}

	.social-media-carousel-slide-graphic {
		width: 100%;
		padding: 2rem;
	}
	.social-media-carousel-slide-content {
		text-align: center;
	}
	.social-media-carousel-slide-content p {
		font-size: 1rem;
		padding: 1rem;
	}
	.carousel-button-light-theme,
	.carousel-button-dark-theme {
		width: 10rem;
	}
	.carousel .slide img {
		display: none;
	}
	.control-arrow,
	.thumbs {
		display: none;
	}
}
