@import url('../../App.css');

.header-desktop section {
	display: flex;
	flex-flow: row nowrap;
	justify-content: space-between;
	padding: 2rem;
}

.header-desktop section .navigation {
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
}

.header-desktop section nav {
	display: flex;
	flex-flow: row nowrap;
	justify-content: space-around;
	width: 30rem;
}

.header-desktop section nav a {
	color: var(--brand-color-white);
	text-decoration: none;
}

/*Responsive Burger Menu */
header {
	/* background: #2f2f3a; */
}

header input {
	opacity: 0;
}

header h1 {
	position: fixed;
	top: 40px;
	left: 40px;
	z-index: 6;
	font-size: 20px;
	font-weight: 900;
	font-family: sans-serif;
	text-transform: uppercase;
}
header h1 > span {
	text-transform: none;
	opacity: 0.5;
	font-weight: 300;
	font-size: 12px;
}
header input + label {
	position: fixed;
	top: 20px;
	right: 40px;
	height: 20px;
	width: 15px;
	z-index: 5;
}
header input + label span {
	position: absolute;
	width: 100%;
	height: 2px;
	top: 50%;
	margin-top: -1px;
	left: 0;
	display: block;
	background: #ffffff;
	transition: 0.5s;
}
header input + label span:first-child {
	top: 3px;
}
header input + label span:last-child {
	top: 16px;
}
header label:hover {
	cursor: pointer;
}
header input:checked + label span {
	opacity: 0;
	top: 50%;
}
header input:checked + label span:first-child {
	opacity: 1;
	transform: rotate(405deg);
}
header input:checked + label span:last-child {
	opacity: 1;
	transform: rotate(-405deg);
}
header input ~ nav {
	background: #3b3970;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 70px;
	z-index: 3;
	transition: 0.5s;
	transition-delay: 0.5s;
	overflow: hidden;
}
header input ~ nav > ul {
	text-align: center;
	position: absolute;
	top: 35%;
	left: 20%;
	right: 20%;
	list-style: none;
}
header input ~ nav > ul > li {
	opacity: 0;
	transition: 0.5s;
	transition-delay: 0s;
}
header input ~ nav > ul > li > a {
	text-decoration: none;
	text-transform: uppercase;
	color: #ffffff;
	font-weight: 700;
	font-family: sans-serif;
	display: block;
	padding: 30px;
}

header input ~ nav > ul li:first-child {
	display: flex;
	justify-content: center;
}

header input:checked ~ nav {
	height: 100%;
	transition-delay: 0s;
}
header input:checked ~ nav > ul > li {
	opacity: 1;
	transition-delay: 0.5s;
}

.logo-theme-toggle {
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	padding: 0.5rem;
	width: 10rem;
	justify-content: space-between;
}

/* 
  ##Device = Desktops
  ##Screen = 1281px to higher resolution desktops
*/

@media (min-width: 1281px) {
	/* CSS */
	.header-desktop {
		display: block;
	}
	.header-mobile {
		display: none;
	}
}

/* 
	##Device = Laptops, Desktops
	##Screen = B/w 1025px to 1280px
  */

@media (min-width: 1025px) and (max-width: 1280px) {
	/* CSS */
	.header-desktop {
		display: block;
	}
	.header-mobile {
		display: none;
	}
}

/* 
	##Device = Tablets, Ipads (portrait)
	##Screen = B/w 768px to 1024px
  */

@media (min-width: 768px) and (max-width: 1024px) {
	/* CSS */
	.header-desktop {
		display: none;
	}
	.header-mobile {
		display: block;
	}
}

/* 
	##Device = Tablets, Ipads (landscape)
	##Screen = B/w 768px to 1024px
  */

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
	/* CSS */
	.header-desktop {
		display: none;
	}
	.header-mobile {
		display: block;
	}
}

/* 
	##Device = Low Resolution Tablets, Mobiles (Landscape)
	##Screen = B/w 481px to 767px
  */

@media (min-width: 481px) and (max-width: 767px) {
	/* CSS */
	.header-desktop {
		display: none;
	}
	.header-mobile {
		display: block;
	}
}

/* 
	##Device = Most of the Smartphones Mobiles (Portrait)
	##Screen = B/w 320px to 479px
  */

@media (min-width: 320px) and (max-width: 480px) {
	/* CSS */
	.header-desktop {
		display: none;
	}
	.header-mobile {
		display: block;
	}
}
