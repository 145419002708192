@import url('../../App.css');

.tech-stack-container {
	display: flex;
	flex-flow: row wrap;
	justify-content: center;
	width: 100rem;
}

.tech-stack {
	text-align: center;
	margin: 1rem 4rem 1rem 4rem;
}

.skill-light-theme {
	color: var(--main-font-color);
}

.skill-dark-theme {
	color: var(--brand-color-purple);
}
